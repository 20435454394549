<template>
  <div class="row">
    <div class="col">

      <!-- table -->
      <div class="table-responsive pb-4">
        <table class="table table-striped table-flush bg-white" id="datatable" v-if="placetypes.length > 0">
          <thead class="thead-light">
            <tr>
              <th>Label</th>
              <th>Value</th>
              <th>Source</th>
              <th class="text-right">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="type, index in placetypes">
              <td>{{ type.label }}</td>
              <td>{{ type.value }}</td>
              <td>{{ type.source }}</td>
              <td class="text-right">
								<div class="dropdown">
									<a class="btn btn-sm btn-icon-only" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<i class="fas fa-cogs"></i>
									</a>
									<div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow text-right">
										<a class="dropdown-item" @click="editData(type)"><i class="far fa-edit"></i> Editar</a>
										<a class="dropdown-item text-danger" @click="deleteData(type.id, index)"><i class="far fa-trash-alt"></i> Excluir</a>
									</div>
								</div>
							</td>
            </tr>
          </tbody>
        </table>
        <div v-else class="text-center p-3 text-muted">
            <h5>Nenhum place type encontrado!</h5>
        </div>
      </div>
      <!-- end table -->

      <div class="text-center p-4 text-muted" v-if="loading && placetypes.length == 0">
        <h5>Carregando...</h5>
        <p>Aguarde até que os dados sejam carregados...</p>
      </div>

    </div><!-- col -->

    <!-- modal place type -->
    <div class="modal fade" id="modalPlaceType" tabindex="-1" aria-labelledby="modalPlaceTypeLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalPlaceTypeLabel">{{title}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form method="POST" v-on:submit.prevent="save">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Label</label>
                    <input type="text" class="form-control" name="placeType.label" id="placeType.label" v-model="placeType.label">
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Value</label>
                    <input type="text" class="form-control" name="placeType.value" id="placeType.value" v-model="placeType.value">
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label>Source</label>
                    <select class="form-control" name="placeType.source" id="placeType.source" v-model="placeType.source">
                      <option>Google</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label>Categoria</label>
                    <select class="form-control" name="placeType.category" id="placeType.category" v-model="placeType.category_id">
                      <option v-for="item in categories" :value="item.id">{{item.name}}</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="text-right">
                    <button type="submit" class="btn btn-success">SAVE</button>
                  </div> 
                </div>    
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end modal place type -->

    <vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
  </div><!-- row -->
</template>

<script>
  export default {
    data() {
      return {
        categories: [],
        errors: [],
        fullPage: true,
        isEdit: false,
        loading: false,
        message: "",
        placeType: {
          label: '',
          value: '',
          source: 'Google',
          category_id: 0
        },
        placeTypeID: '',
        placetypes: [],
        title: 'Novo Place Type',
      }
    },
    mounted() {
      this.loadPlaceTypes();
      this.loadCategories();
    },
    methods: {
      deleteData(ID){

        let _this = this;

        //show alert confirmation
        _this.$swal({
          title: "Remover Place Type",
          text: "Tem certeza que deseja removere esse place type?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: 'Remove',
          cancelButtonText: 'Cancel'
        }).then((alertConfirm) => {

          //validate isConfirmed
          if (alertConfirm.isConfirmed) {

            //remove user
            axios.delete('/admin/placetypes/'+ID).then((res) => {

              _this.loadPlaceTypes();

              //show confirm alert
              _this.$swal({
                title: 'Sucesso',
                text: 'Place type removido com sucesso!',
                icon: 'success'
              });

            }).catch(error => {

              //show error alert
              _this.$swal({
                title: 'Erro',
                text: 'Falha ao remover o place type. Tente novamente!',
                icon: 'error'
              });

            });

          } else {
            
            //only close

          }//end validate isConfirmed

        });
        //end alert confirmation

      },
      editData(item){
        this.placeType = item;
        this.isEdit = true;
        this.placeTypeID = item.id;
        this.title = "Editar Place Type";

        console.log(item);

        $("#modalPlaceType").modal('show');
      },
      loadCategories(){
        let _this = this;
				_this.loading = true;
				axios.get("/admin/categories").then((res) => {
					_this.categories = _this.categories.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
      },
      loadPlaceTypes() {
        this.placeTypes = [];
        let _this = this;
        _this.loading = true;
        axios.get('/admin/placetypes').then((res) => {
          _this.placetypes = res.data;
          _this.loading = false;
        }).catch((err) => {
          _this.loading = false;
        });
      },
      resetForm(){
        this.placeType = {
          label: '',
          value: '',
          source: 'Google'
        };
      },
      save(){

        //validate if is edit
        if (this.isEdit == true){

          //edit
          let _this = this;
          _this.errors = [];
          _this.message = '';
          _this.loading = true;
          $("#modalPlaceType").modal('hide');
          _this.title = 'Novo Place Type';
          let dateLabel = {
            'label': _this.placeType.label,
            'value': _this.placeType.value,
            'source': _this.placeType.source,
            'category': _this.placeType.category_id,
            "_method": "PATCH"
          };
          
          axios.post('/admin/placetypes/'+_this.placeTypeID, dateLabel).then((res) => { 
            
            _this.loading = false;
            _this.resetForm();
            _this.loadPlaceTypes();
            _this.isEdit = false;
            _this.placeTypeID = '';

            //show confirm alert
            _this.$swal({
              title: 'Sucesso',
              text: 'Place type atualizado com sucesso!',
              icon: 'success'
            });
          }).catch((err) => {

            _this.loading = false;

            //show error alert
            _this.$swal({
              title: 'Erro',
              text: 'Falha ao atualizar o place type. Tente novamente!',
              icon: 'error'
            });
          });

        } else {

          //new
          let _this = this;
          _this.errors = [];
          _this.message = '';
          _this.loading = true;
          $("#modalPlaceType").modal('hide');
          let dateLabel = {
            'label': _this.placeType.label,
            'value': _this.placeType.value,
            'source': _this.placeType.source,
            'category': _this.placeType.category_id,
          };
          
          axios.post('/admin/placetypes', dateLabel).then((res) => { 
            
            _this.loading = false;
            _this.resetForm();
            _this.loadPlaceTypes();

            //show confirm alert
            _this.$swal({
              title: 'Sucesso',
              text: 'Place type adicionado com sucesso!',
              icon: 'success'
            });
          }).catch((err) => {

            _this.loading = false;

            //show error alert
            _this.$swal({
              title: 'Erro',
              text: 'Falha ao cadastrar place type. Tente novamente!',
              icon: 'error'
            });
          });

        }
        //end validate if is edit

      },
    }
  }
</script>
