<template>
	<div class="container-fluid">
		<div class="row pad0">
      <div class="col-md-12 pad0">

        <!-- form -->
        <div class="card">
          <div class="card-header">
            <h4>Busca</h4>
          </div>

          <div class="card-body">
            <div class="row">

              <div class="col-md-3">
                <div class="form-group">
                  <label>Latitude</label>
                  <input type="text" class="form-control" id="latitude" name="latitude" v-model="latitude">
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label>Longitude</label>
                  <input type="text" class="form-control" id="longitude" name="longitude" v-model="longitude">
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label>Tipo</label>
                  <select class="form-control" v-model="type" id="type" name="type">
                    <option v-for="item in types" :value="item.value">{{item.label}} ({{item.category.name}})</option>
                  </select>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group mt-1">
                  <button @click="searchPlaces" class="btn btn-success mt-4 float-right">BUSCAR</button>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <!-- end form -->

      </div>
    </div>

    <div class="row pad0">
      <!-- list -->
      <div class="col-md-8 padLeft0">
        <div class="card">
          <div class="card-header">
            <h4>Resultados da Busca</h4>
          </div>

          <div class="card-body">
            <div class="table-responsive" v-if="places.length > 0">
              <table class="table table-striped align-items-center table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Name</th>
                    <th>Rating</th>
                    <th>Ratings Total</th>
                    <th>Status</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody class="list">
                  <tr v-for="item in places">
                    <td :class="item.exists ? 'text-success' : 'text-danger'">{{item.name}}</td>
                    <td>{{item.rating}}</td>
                    <td>{{item.user_ratings_total}}</td>
                    <td>{{item.business_status}}</td>
                    <td>
                      <a @click="viewPlace(item.place_id)" class="btn btn-light">VER</a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="text-center" v-if="pageToken != '' && pageToken != undefined">
                <a @click="morePlaces" class="btn btn-warning">VER MAIS</a>
              </div>
            </div>
            <div class="text-center" v-else>
              Nenhum local encontrado!
            </div>
          </div>
        </div>
      </div>
      <!-- end list -->

      <!-- selected place -->
      <div class="col-md-4 padRight0">
        <div class="card">
          <div class="card-header">
            <h4>Local</h4>
          </div>

          <div class="card-body pad0">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Nome</label>
                  <input type="text" class="form-control" id="name" name="name" v-model="place.name">
                </div>
              </div>

              <!-- <div class="col-md-12">
                <div class="form-group">
                  <label>ID</label>
                  <input type="text" class="form-control" id="id" name="id" v-model="place.place_id">
                </div>
              </div> -->

              <div class="col-md-8">
                <div class="form-group">
                  <label>Endereço</label>
                  <input type="text" class="form-control" id="address" name="address" v-model="place.address">
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label>Nº</label>
                  <input type="text" class="form-control" id="number" name="number" v-model="place.number">
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group">
                  <label>Bairro</label>
                  <input type="text" class="form-control" id="neighborhood" name="neighborhood" v-model="place.neighborhood">
                </div>
              </div>

              <div class="col-md-8">
                <div class="form-group">
                  <label>Cidade</label>
                  <input type="text" class="form-control" id="city" name="city" v-model="place.city">
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label>Estado</label>
                  <input type="text" class="form-control" id="state" name="state" v-model="place.state">
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label>País</label>
                  <input type="text" class="form-control" id="country" name="country" v-model="place.country">
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label>CEP</label>
                  <input type="text" class="form-control" id="cep" name="cep" v-model="place.cep">
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label>Latitude</label>
                  <input type="text" class="form-control" id="lat" name="lat" v-model="place.lat">
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label>Longitude</label>
                  <input type="text" class="form-control" id="lng" name="lng" v-model="place.lng">
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group">
                  <label>Telefone</label>
                  <input type="text" class="form-control" id="phone" name="phone" v-model="place.phone">
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group">
                  <label>URL <a :href="place.url" class="btn btn-sm btn-light" target="_blank"><i class="fas fa-globe"></i></a></label>
                  <input type="text" class="form-control" id="url" name="url" v-model="place.url">
                </div>
              </div>

              <div class="col-md-12">
                <button class="btn btn-outline-success" @click="importPlace()">IMPORTAR</button>
              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- end selected place -->
    </div>

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				googleKey: 'AIzaSyClFnDU0RmMf8oPQ9tqIlOEas5CucUc1wo',
        places: [],
        latitude: '-22.8770256', //Hortolandia
        longitude: '-47.2440488', //Hortolandia
        pageToken: '',
        categoryID: '',
        companies: [],
        place: {
          name: '',
          place_id: '',
          address: '',
          number: '',
          neighborhood: '',
          city: '',
          state: '',
          country: '',
          cep: '',
          lat: '',
          lng: '',
          phone: '',
          url: '',
          photos: []
        },
        type: '',
        types: [],
				errors: [],
				message: "",
				loading: false,
				fullPage: true
			}
		},
		mounted() {
      this.loadCompanies();
			this.loadPlaceTypes();
		},
		methods: {
			importPlace(){

        let _this = this;
				_this.errors = [];
				_this.message = "";
				_this.loading = true;
				let dataInfo = {
          "name" : _this.place.name,
          "cep" : _this.place.cep,
          "address" : _this.place.address,
          "number" : _this.place.number,
          "neighborhood" : _this.place.neighborhood,
          "city" : _this.place.city,
          "state" : _this.place.state,
          "country" : _this.place.country,
          "latitude" : _this.place.lat,
          "longitude" : _this.place.lng,
          "phone" : _this.place.phone,
          "category" : _this.categoryID,
          "place_id" : _this.place.place_id,
          "place_url" : _this.place.url
				};
				axios.post("/admin/integration/place/store", dataInfo).then((res) => {

          //update exists
          _this.places.map((row) => {
            if (row.place_id == _this.place.place_id){
              row.exists = true;
            }
          });
          //end update exists

					_this.loading = false;
          _this.resetForm();

					//show confirm alert
					_this.$swal({
						title: "Sucesso",
						text: "Place importado com sucesso!",
						icon: "success"
					});
				}).catch((err) => {

					_this.loading = false;

					//show error alert
					_this.$swal({
						title: "Erro",
						text: "Falha ao importar place. Tente novamente!",
						icon: "error"
					});
				});

      },
      loadCompanies(){
        let _this = this;
				_this.loading = true;
				axios.get("/admin/companies").then((res) => {
					_this.companies = _this.companies.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
        });
      },
			loadPlaceTypes(){
				this.types = [];
        let _this = this;
        _this.loading = true;
        axios.get('/admin/placetypes').then((res) => {
          _this.types = res.data;
          _this.loading = false;
        }).catch((err) => {
          _this.loading = false;
        });
      },
      resetForm(){
        this.place = {
          name: '',
          place_id: '',
          address: '',
          number: '',
          neighborhood: '',
          city: '',
          state: '',
          country: '',
          cep: '',
          lat: '',
          lng: '',
          phone: '',
          url: '',
          photos: []
        };
      },
			searchPlaces(){

        let _this = this;
				_this.errors = [];
				_this.message = "";
				_this.loading = true;

				let dataInfo = {
          "latitude" : _this.latitude,
          "longitude" : _this.longitude,
          "type" : _this.type,
          "pageToken": null
				};
        axios.post('/admin/companies/integrator/places', dataInfo).then((res) => {

          var resultado = JSON.parse(res.data);

          //get page token
          _this.pageToken = resultado.next_page_token;

          resultado.results.map((row) => {
            row.exists = _this.companies.some(data => data.place_id === row.place_id);
          });

          //get results
          _this.places = resultado.results;

          //validate category
          _this.types.map((row) => {
            if (row.value == _this.type){
              _this.categoryID = row.category_id;
            }
          });
          //end validate category 

          _this.loading = false;

        });
      },
      morePlaces(){

        let _this = this;
				_this.errors = [];
				_this.message = "";
				_this.loading = true;

				let dataInfo = {
          "latitude" : _this.latitude,
          "longitude" : _this.longitude,
          "type" : _this.type,
          "pageToken": _this.pageToken
				};
        axios.post('/admin/companies/integrator/places', dataInfo).then((res) => {

          var resultado = JSON.parse(res.data);

          //get page token
          _this.pageToken = resultado.next_page_token;

          //get results
          resultado.results.map((row) => {
            row.exists = _this.companies.some(data => data.place_id === row.place_id);
            _this.places.push(row);
          });

          _this.loading = false;

        });
      },
      viewPlace(id){
         let _this = this;
				_this.errors = [];
				_this.message = "";
				_this.loading = true;
        axios.get('/admin/companies/integrator/places/'+id+'/view').then((res) => {

          var resultado = JSON.parse(res.data);

          //apply place
          _this.place = {
            place_id: resultado.result.place_id,
            name: resultado.result.name,
            address: resultado.result.address_components[1].long_name,
            number: resultado.result.address_components[0].long_name,
            neighborhood: resultado.result.address_components[2].long_name,
            city: resultado.result.address_components[3].long_name,
            state: resultado.result.address_components[4].short_name,
            country: resultado.result.address_components[5].long_name,
            cep: resultado.result.address_components[6].long_name,
            lat: resultado.result.geometry.location.lat,
            lng: resultado.result.geometry.location.lng,
            url: resultado.result.url
          };

          this.loading = false;

          //read photos
          //manual upload logo
          //manual upload cover
          //manual upload photos
          // res.data.result.photos.map((row) => {

          //   axios.get('https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/photo?maxwidth='+row.width+'&maxheight='+row.height+'&photoreference='+row.photo_reference+'&key='+this.googleKey).then((resPhoto) => {

          //     console.log(resPhoto);

          //   });

          // });

        });
      }
		}
	}
</script>
