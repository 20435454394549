<template>
	<div class="row">
		<div class="col-12">
			<div class="card">

				<!-- card header -->
				<div class="card-header">
					<h4>Preencha os dados abaixo</h4>

					<a @click="newData" class="btn btn-sm btn-success text-white btn-right">SALVAR</a>
				</div>
				<!-- end card header -->

				<!-- card body -->
				<div class="card-body">

					<!-- form -->
					<h6 class="heading-small text-muted mb-4">Dados</h6>
					<div class="">
						<div class="row">

							<!-- name -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-name">Nome</label>
									<input type="text" id="input-name" v-bind:class="{'is-invalid': errors.name}" class="form-control" placeholder="Nome" v-model="name" required="true">
									<div class="invalid-feedback" v-if="errors.name">
										<p>{{ errors.name[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end name -->

							<!-- cnpj -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-cnpj">CNPJ</label>
									<input type="text" id="input-cnpj" v-bind:class="{'is-invalid': errors.cnpj}" class="form-control" placeholder="CNPJ" v-model="cnpj" required="true">
									<div class="invalid-feedback" v-if="errors.cnpj">
										<p>{{ errors.cnpj[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end cnpj -->

							<!-- cep -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-cep">CEP</label>
									<input type="text" id="input-cep" v-bind:class="{'is-invalid': errors.cep}" class="form-control" placeholder="CEP" v-model="cep" required="true">
									<div class="invalid-feedback" v-if="errors.cep">
										<p>{{ errors.cep[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end cep -->

							<!-- address -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-address">Endereço</label>
									<input type="text" id="input-address" v-bind:class="{'is-invalid': errors.address}" class="form-control" placeholder="Endereço" v-model="address" required="true">
									<div class="invalid-feedback" v-if="errors.address">
										<p>{{ errors.address[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end address -->

							<!-- number -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-number">Número</label>
									<input type="text" id="input-number" v-bind:class="{'is-invalid': errors.number}" class="form-control" placeholder="Número" v-model="number" required="true">
									<div class="invalid-feedback" v-if="errors.number">
										<p>{{ errors.number[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end number -->

							<!-- complement -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-complement">Complemento</label>
									<input type="text" id="input-complement" v-bind:class="{'is-invalid': errors.complement}" class="form-control" placeholder="Complemento" v-model="complement" required="true">
									<div class="invalid-feedback" v-if="errors.complement">
										<p>{{ errors.complement[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end complement -->

							<!-- neighborhood -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-neighborhood">Bairro</label>
									<input type="text" id="input-neighborhood" v-bind:class="{'is-invalid': errors.neighborhood}" class="form-control" placeholder="Bairro" v-model="neighborhood" required="true">
									<div class="invalid-feedback" v-if="errors.neighborhood">
										<p>{{ errors.neighborhood[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end neighborhood -->

							<!-- city -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-city">Cidade</label>
									<input type="text" id="input-city" v-bind:class="{'is-invalid': errors.city}" class="form-control" placeholder="Cidade" v-model="city" required="true">
									<div class="invalid-feedback" v-if="errors.city">
										<p>{{ errors.city[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end city -->

							<!-- state -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-state">Estado</label>
									<input type="text" id="input-state" v-bind:class="{'is-invalid': errors.state}" class="form-control" placeholder="Estado" v-model="state" required="true">
									<div class="invalid-feedback" v-if="errors.state">
										<p>{{ errors.state[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end state -->

							<!-- country -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-country">País</label>
									<input type="text" id="input-country" v-bind:class="{'is-invalid': errors.country}" class="form-control" placeholder="País" v-model="country" required="true">
									<div class="invalid-feedback" v-if="errors.country">
										<p>{{ errors.country[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end country -->

							<!-- latitude -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-latitude">Latitude</label>
									<input type="text" id="input-latitude" v-bind:class="{'is-invalid': errors.latitude}" class="form-control" placeholder="Latitude" v-model="latitude" required="true">
									<div class="invalid-feedback" v-if="errors.latitude">
										<p>{{ errors.latitude[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end latitude -->

							<!-- longitude -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-longitude">Longitude</label>
									<input type="text" id="input-longitude" v-bind:class="{'is-invalid': errors.longitude}" class="form-control" placeholder="Longitude" v-model="longitude" required="true">
									<div class="invalid-feedback" v-if="errors.longitude">
										<p>{{ errors.longitude[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end longitude -->

							<!-- website -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-website">Website</label>
									<input type="text" id="input-website" v-bind:class="{'is-invalid': errors.website}" class="form-control" placeholder="Website" v-model="website" required="true">
									<div class="invalid-feedback" v-if="errors.website">
										<p>{{ errors.website[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end website -->

							<!-- email -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-email">E-mail</label>
									<input type="text" id="input-email" v-bind:class="{'is-invalid': errors.email}" class="form-control" placeholder="E-mail" v-model="email" required="true">
									<div class="invalid-feedback" v-if="errors.email">
										<p>{{ errors.email[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end email -->

							<!-- payments -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-payments">Formas de Pagamento</label>
									<textarea type="text" id="input-payments" v-bind:class="{'is-invalid': errors.payments}" class="form-control" placeholder="Formas de Pagamento" v-model="payments" rows="8" required="true"></textarea>
									<div class="invalid-feedback" v-if="errors.payments">
										<p>{{ errors.payments[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-12 -->
							<!-- end payments -->

							<!-- about -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-about">Sobre</label>
									<textarea type="text" id="input-about" v-bind:class="{'is-invalid': errors.about}" class="form-control" placeholder="Sobre" v-model="about" rows="8" required="true"></textarea>
									<div class="invalid-feedback" v-if="errors.about">
										<p>{{ errors.about[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-12 -->
							<!-- end about -->

							<!-- details -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-details">Detalhes</label>
									<textarea type="text" id="input-details" v-bind:class="{'is-invalid': errors.details}" class="form-control" placeholder="Detalhes" v-model="details" rows="8" required="true"></textarea>
									<div class="invalid-feedback" v-if="errors.details">
										<p>{{ errors.details[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-12 -->
							<!-- end details -->

							<!-- additional_information -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-additional_information">Informações Adicionais</label>
									<textarea type="text" id="input-additional_information" v-bind:class="{'is-invalid': errors.additional_information}" class="form-control" placeholder="Informações Adicionais" v-model="additional_information" rows="8" required="true"></textarea>
									<div class="invalid-feedback" v-if="errors.additional_information">
										<p>{{ errors.additional_information[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-12 -->
							<!-- end additional_information -->

							<!-- logo -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-logo">Logotipo</label>
									<div class="custom-file">
										<input type="file" class="custom-file-input" id="logo" name="logo" accept="image/*" ref="logo" lang="pt" v-on:change="handleFileUpload('logo')">
										<label class="custom-file-label" for="logo"></label>
									</div>
									<div class="invalid-feedback" v-if="errors.logo">
										<p>{{ errors.logo[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-12 -->
							<!-- end logo -->

							<!-- cover -->
							<div class="col-md-12">
								<div class="form-group">
									<label class="form-control-label" for="input-cover">Capa</label>
									<div class="custom-file">
										<input type="file" class="custom-file-input" id="cover" name="cover" accept="image/*" ref="cover" lang="pt" v-on:change="handleFileUpload('cover')">
										<label class="custom-file-label" for="cover"></label>
									</div>
									<div class="invalid-feedback" v-if="errors.cover">
										<p>{{ errors.cover[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-12 -->
							<!-- end cover -->

							<!-- spotlight -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-spotlight">Destaque</label>
									<select type="text" id="input-spotlight" v-bind:class="{'is-invalid': errors.spotlight}" class="form-control" placeholder="Destaque" v-model="spotlight" required="true" data-toggle="select">
										<option></option>
									</select>
									<div class="invalid-feedback" v-if="errors.spotlight">
										<p>{{ errors.spotlight[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end spotlight -->

							<!-- virtual_card -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-virtual_card">Cartão Virtual</label>
									<input type="text" id="input-virtual_card" v-bind:class="{'is-invalid': errors.virtual_card}" class="form-control" placeholder="Cartão Virtual" v-model="virtual_card" required="true">
									<div class="invalid-feedback" v-if="errors.virtual_card">
										<p>{{ errors.virtual_card[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end virtual_card -->

							<!-- link -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-link">Link</label>
									<input type="text" id="input-link" v-bind:class="{'is-invalid': errors.link}" class="form-control" placeholder="Link" v-model="link" required="true">
									<div class="invalid-feedback" v-if="errors.link">
										<p>{{ errors.link[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end link -->

							<!-- category_id -->
							<div class="col-md-6">
								<div class="form-group">
									<label class="form-control-label" for="input-category_id">Categories</label>
									<select type="text" id="input-category_id" v-bind:class="{'is-invalid': errors.category_id}" class="form-control" placeholder="Categories" v-model="category_id" required="true" data-toggle="select">
										<option v-for="item in category">{{item.name}}</option>
									</select>
									<div class="invalid-feedback" v-if="errors.category_id">
										<p>{{ errors.category_id[0] }}</p>
									</div>
								</div><!-- form group -->
							</div><!-- col-md-6 -->
							<!-- end category_id -->

						</div>
					</div>
					<!-- end form -->

				</div><!-- card body -->
			</div><!-- card -->
		</div><!-- col-12 -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
	</div><!-- row -->
</template>

<script>
	import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
	export default {
		data() {
			return {
				name: "",
				cnpj: "",
				cep: "",
				address: "",
				number: "",
				complement: "",
				neighborhood: "",
				city: "",
				state: "",
				country: "",
				latitude: "",
				longitude: "",
				website: "",
				email: "",
				payments: "",
				about: "",
				details: "",
				additional_information: "",
				logo: "",
				cover: "",
				spotlight: "",
				virtual_card: "",
				link: "",
				category_id: "",
				category: [],
				errors: [],
				message: "",
				loading: false,
				fullPage: true
			}
		},
		mounted() {
			this.loadCategory();
		},
		methods: {
			handleFileUpload(field){
				this.field = this.$refs.field.files[0];
			},
			newData() {
				let _this = this;
				_this.errors = [];
				_this.message = "";
				_this.loading = true;
				let dataInfo = {
					"name" : _this.name,
					"cnpj" : _this.cnpj,
					"cep" : _this.cep,
					"address" : _this.address,
					"number" : _this.number,
					"complement" : _this.complement,
					"neighborhood" : _this.neighborhood,
					"city" : _this.city,
					"state" : _this.state,
					"country" : _this.country,
					"latitude" : _this.latitude,
					"longitude" : _this.longitude,
					"website" : _this.website,
					"email" : _this.email,
					"payments" : _this.payments,
					"about" : _this.about,
					"details" : _this.details,
					"additional_information" : _this.additional_information,
					"logo" : _this.logo,
					"cover" : _this.cover,
					"spotlight" : _this.spotlight,
					"virtual_card" : _this.virtual_card,
					"link" : _this.link,
					"category_id" : _this.category_id,
					"last": ""
				};
				axios.post("/admin/companies", dataInfo).then((res) => {

					_this.loading = false;
					_this.resetForm();

					//show confirm alert
					_this.$swal({
						title: "Sucesso",
						text: "Companies cadastrado com sucesso!",
						icon: "success"
					});
				}).catch((err) => {

					_this.loading = false;

					//show error alert
					_this.$swal({
						title: "Erro",
						text: "Falha ao cadastrar Companies. Tente novamente!",
						icon: "error"
					});
				});
			},
			resetForm() {
				this.name = '';
				this.cnpj = '';
				this.cep = '';
				this.address = '';
				this.number = '';
				this.complement = '';
				this.neighborhood = '';
				this.city = '';
				this.state = '';
				this.country = '';
				this.latitude = '';
				this.longitude = '';
				this.website = '';
				this.email = '';
				this.payments = '';
				this.about = '';
				this.details = '';
				this.additional_information = '';
				this.logo = '';
				this.cover = '';
				this.spotlight = '';
				this.virtual_card = '';
				this.link = '';
				this.category_id = '';
			},
			loadCategory() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/category").then((res) => {
					_this.category = _this.category.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			}
		}
	}
</script>
