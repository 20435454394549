<template>
  <div class="row">
    <div class="col">

      <!-- table -->
      <div class="table-responsive pb-4">
        <table class="table table-striped table-flush bg-white" id="datatable">
          <thead class="thead-light">
            <tr>
              <th>E-mail</th>
              <th>IP</th>
              <th>Descrição</th>
              <th>Status</th>
              <th>Data e Hora</th>
            </tr>
          </thead>
          <tbody>
            <tr class="title">
              <td>E-mail</td>
              <td>IP</td>
              <td>Descrição</td>
              <td>Status</td>
              <td>Data e Hora</td>
            </tr>
            <tr v-for="log, index in logs">
              <td>{{ log.email }}</td>
              <td>{{ log.ip }}</td>
              <td>{{ log.description }}</td>
              <td>{{ log.status }}</td>
              <td>{{ log.created_at | moment('DD/MM/YYYY HH:mm:ss') }}</td>
            </tr>
          </tbody>
        </table>
        <div v-if="!logs.length" class="text-center p-3 text-muted">
            <h5>Nenhum log encontrado!</h5>
        </div>
      </div>
      <!-- end table -->

      <div class="text-center p-4 text-muted" v-if="loading">
        <h5>Carregando...</h5>
        <p>Aguarde até que os dados sejam carregados...</p>
      </div>

    </div><!-- col -->
  </div><!-- row -->
</template>

<script>
  export default {
    data() {
      return {
        logs: [],
        loading: false
      }
    },
    mounted() {
      this.loadLogs();
    },
    methods: {
      loadLogs() {
        let _this = this;
        _this.loading = true;
        axios.get('/admin/log/access').then((res) => {
          _this.logs = _this.logs.concat(res.data);
          _this.loading = false;
        }).catch((err) => {
          _this.loading = false;
        });
      }
    }
  }
</script>
