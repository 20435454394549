require('./bootstrap');

window.Vue = require('vue');

//ALERT
import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);

//CKeditor
import CKEditor from '@ckeditor/ckeditor5-vue';
Vue.use( CKEditor );

//LOADING
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading);
Vue.component('vue-loading', Loading);

//MOMENT
Vue.use(require('vue-moment'));

//COMPONENTS
//dashboard
import adminWidgetsComponent from './components/admin/dashboard/widgets';
Vue.component('adminwidgets-component', adminWidgetsComponent);

//logs
import LogAccessComponent from './components/admin/logs/access';
import LogActivityComponent from './components/admin/logs/activity';
import LogErrorComponent from './components/admin/logs/error';
Vue.component('logaccess-component',LogAccessComponent);
Vue.component('logactivity-component',LogActivityComponent);
Vue.component('logerror-component',LogErrorComponent);

//users
import userListComponent from './components/admin/users/list';
import userAddComponent from './components/admin/users/add';
import userEditComponent from './components/admin/users/edit';
import userProfileComponent from './components/admin/users/profile'
Vue.component('userlist-component', userListComponent);
Vue.component('useradd-component', userAddComponent);
Vue.component('useredit-component', userEditComponent);
Vue.component('userprofile-component', userProfileComponent);

//notificationusers
import notificationusersListComponent from './components/admin/notificationusers/list';
import notificationusersAddComponent from './components/admin/notificationusers/add';
import notificationusersEditComponent from './components/admin/notificationusers/edit';
Vue.component('notificationuserslist-component', notificationusersListComponent);
Vue.component('notificationusersadd-component', notificationusersAddComponent);
Vue.component('notificationusersedit-component', notificationusersEditComponent);

//notifications
import notificationsListComponent from './components/admin/notifications/list';
import notificationsAddComponent from './components/admin/notifications/add';
import notificationsEditComponent from './components/admin/notifications/edit';
Vue.component('notificationslist-component', notificationsListComponent);
Vue.component('notificationsadd-component', notificationsAddComponent);
Vue.component('notificationsedit-component', notificationsEditComponent);

//videos
import videosListComponent from './components/admin/videos/list';
import videosAddComponent from './components/admin/videos/add';
import videosEditComponent from './components/admin/videos/edit';
Vue.component('videoslist-component', videosListComponent);
Vue.component('videosadd-component', videosAddComponent);
Vue.component('videosedit-component', videosEditComponent);

//complaints
import complaintsListComponent from './components/admin/complaints/list';
import complaintsAddComponent from './components/admin/complaints/add';
import complaintsEditComponent from './components/admin/complaints/edit';
Vue.component('complaintslist-component', complaintsListComponent);
Vue.component('complaintsadd-component', complaintsAddComponent);
Vue.component('complaintsedit-component', complaintsEditComponent);

//companyevaluations
import companyevaluationsListComponent from './components/admin/companyevaluations/list';
import companyevaluationsAddComponent from './components/admin/companyevaluations/add';
import companyevaluationsEditComponent from './components/admin/companyevaluations/edit';
Vue.component('companyevaluationslist-component', companyevaluationsListComponent);
Vue.component('companyevaluationsadd-component', companyevaluationsAddComponent);
Vue.component('companyevaluationsedit-component', companyevaluationsEditComponent);

//favorites
import favoritesListComponent from './components/admin/favorites/list';
import favoritesAddComponent from './components/admin/favorites/add';
import favoritesEditComponent from './components/admin/favorites/edit';
Vue.component('favoriteslist-component', favoritesListComponent);
Vue.component('favoritesadd-component', favoritesAddComponent);
Vue.component('favoritesedit-component', favoritesEditComponent);

//planusers
import planusersListComponent from './components/admin/planusers/list';
import planusersAddComponent from './components/admin/planusers/add';
import planusersEditComponent from './components/admin/planusers/edit';
Vue.component('planuserslist-component', planusersListComponent);
Vue.component('planusersadd-component', planusersAddComponent);
Vue.component('planusersedit-component', planusersEditComponent);

//plans
import plansListComponent from './components/admin/plans/list';
import plansAddComponent from './components/admin/plans/add';
import plansEditComponent from './components/admin/plans/edit';
Vue.component('planslist-component', plansListComponent);
Vue.component('plansadd-component', plansAddComponent);
Vue.component('plansedit-component', plansEditComponent);

//supportanswers
import supportanswersListComponent from './components/admin/supportanswers/list';
import supportanswersAddComponent from './components/admin/supportanswers/add';
import supportanswersEditComponent from './components/admin/supportanswers/edit';
Vue.component('supportanswerslist-component', supportanswersListComponent);
Vue.component('supportanswersadd-component', supportanswersAddComponent);
Vue.component('supportanswersedit-component', supportanswersEditComponent);

//supports
import supportsListComponent from './components/admin/supports/list';
import supportsAddComponent from './components/admin/supports/add';
import supportsEditComponent from './components/admin/supports/edit';
Vue.component('supportslist-component', supportsListComponent);
Vue.component('supportsadd-component', supportsAddComponent);
Vue.component('supportsedit-component', supportsEditComponent);

//contacts
import contactsListComponent from './components/admin/contacts/list';
import contactsAddComponent from './components/admin/contacts/add';
import contactsEditComponent from './components/admin/contacts/edit';
Vue.component('contactslist-component', contactsListComponent);
Vue.component('contactsadd-component', contactsAddComponent);
Vue.component('contactsedit-component', contactsEditComponent);

//userconfigurations
import userconfigurationsListComponent from './components/admin/userconfigurations/list';
import userconfigurationsAddComponent from './components/admin/userconfigurations/add';
import userconfigurationsEditComponent from './components/admin/userconfigurations/edit';
Vue.component('userconfigurationslist-component', userconfigurationsListComponent);
Vue.component('userconfigurationsadd-component', userconfigurationsAddComponent);
Vue.component('userconfigurationsedit-component', userconfigurationsEditComponent);

//companyconfigurations
import companyconfigurationsListComponent from './components/admin/companyconfigurations/list';
import companyconfigurationsAddComponent from './components/admin/companyconfigurations/add';
import companyconfigurationsEditComponent from './components/admin/companyconfigurations/edit';
Vue.component('companyconfigurationslist-component', companyconfigurationsListComponent);
Vue.component('companyconfigurationsadd-component', companyconfigurationsAddComponent);
Vue.component('companyconfigurationsedit-component', companyconfigurationsEditComponent);

//pictures
import picturesListComponent from './components/admin/pictures/list';
import picturesAddComponent from './components/admin/pictures/add';
import picturesEditComponent from './components/admin/pictures/edit';
Vue.component('pictureslist-component', picturesListComponent);
Vue.component('picturesadd-component', picturesAddComponent);
Vue.component('picturesedit-component', picturesEditComponent);

//services
import servicesListComponent from './components/admin/services/list';
import servicesAddComponent from './components/admin/services/add';
import servicesEditComponent from './components/admin/services/edit';
Vue.component('serviceslist-component', servicesListComponent);
Vue.component('servicesadd-component', servicesAddComponent);
Vue.component('servicesedit-component', servicesEditComponent);

//products
import productsListComponent from './components/admin/products/list';
import productsAddComponent from './components/admin/products/add';
import productsEditComponent from './components/admin/products/edit';
Vue.component('productslist-component', productsListComponent);
Vue.component('productsadd-component', productsAddComponent);
Vue.component('productsedit-component', productsEditComponent);

//productcategories
import productcategoriesListComponent from './components/admin/productcategories/list';
import productcategoriesAddComponent from './components/admin/productcategories/add';
import productcategoriesEditComponent from './components/admin/productcategories/edit';
Vue.component('productcategorieslist-component', productcategoriesListComponent);
Vue.component('productcategoriesadd-component', productcategoriesAddComponent);
Vue.component('productcategoriesedit-component', productcategoriesEditComponent);

//companysocials
import companysocialsListComponent from './components/admin/companysocials/list';
import companysocialsAddComponent from './components/admin/companysocials/add';
import companysocialsEditComponent from './components/admin/companysocials/edit';
Vue.component('companysocialslist-component', companysocialsListComponent);
Vue.component('companysocialsadd-component', companysocialsAddComponent);
Vue.component('companysocialsedit-component', companysocialsEditComponent);

//companyoperations
import companyoperationsListComponent from './components/admin/companyoperations/list';
import companyoperationsAddComponent from './components/admin/companyoperations/add';
import companyoperationsEditComponent from './components/admin/companyoperations/edit';
Vue.component('companyoperationslist-component', companyoperationsListComponent);
Vue.component('companyoperationsadd-component', companyoperationsAddComponent);
Vue.component('companyoperationsedit-component', companyoperationsEditComponent);

//companyphones
import companyphonesListComponent from './components/admin/companyphones/list';
import companyphonesAddComponent from './components/admin/companyphones/add';
import companyphonesEditComponent from './components/admin/companyphones/edit';
Vue.component('companyphoneslist-component', companyphonesListComponent);
Vue.component('companyphonesadd-component', companyphonesAddComponent);
Vue.component('companyphonesedit-component', companyphonesEditComponent);

//companies
import companiesListComponent from './components/admin/companies/list';
import companiesAddComponent from './components/admin/companies/add';
import companiesEditComponent from './components/admin/companies/edit';
import companiesIntegratorComponent from './components/admin/companies/integrator';
Vue.component('companieslist-component', companiesListComponent);
Vue.component('companiesadd-component', companiesAddComponent);
Vue.component('companiesedit-component', companiesEditComponent);
Vue.component('companiesintegrator-component', companiesIntegratorComponent);

//categories
import categoriesListComponent from './components/admin/categories/list';
import categoriesAddComponent from './components/admin/categories/add';
import categoriesEditComponent from './components/admin/categories/edit';
Vue.component('categorieslist-component', categoriesListComponent);
Vue.component('categoriesadd-component', categoriesAddComponent);
Vue.component('categoriesedit-component', categoriesEditComponent);

//integrations
import integrationsPlacesTypesComponent from './components/admin/integrations/placetypes';
Vue.component('integrations-placestypes-component',integrationsPlacesTypesComponent);


//RENDER
const app = new Vue({
	el: '#app',
	methods: {
		MakeUrl(path) {
			return BaseUrl(path);
		}
	}
});
