<template>
	<div class="row">
		<div class="col-12">
			<div class="card">

				<!-- card header -->
				<!-- card header -->
				<div class="card-header">
					<h4>Preencha os dados abaixo</h4>

					<a @click="updateData" class="btn btn-sm btn-success text-white btn-right">SALVAR</a>
				</div>
				<!-- end card header -->

				<!-- card body -->
				<div class="card-body">

					<!-- form -->
					<h6 class="heading-small text-muted mb-4">Dados</h6>
					<div class="">
						<div class="row">

						</div>
					</div>
					<!-- end form -->

				</div><!-- card body -->
			</div><!-- card -->
		</div><!-- col-12 -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
	</div><!-- row -->
</template>

<script>
	import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
	export default {
	props: ["companyevaluations"],
		data() {
			return {
				errors: [],
				message: "",
				loading: false,
				fullPage: true
			}
		},
		mounted() {
		},
		methods: {
			getData(key) {
				return JSON.parse(this.companyevaluations)[key];
			},
			handleFileUpload(field){
				this.field = this.$refs.field.files[0];
			},
			updateData() {
				let _this = this;
				_this.errors = [];
				_this.message = "";
				_this.loading = true;
				let dataInfo = {
					"_method": "PATCH"
				};
				axios.post("/admin/companyevaluations/"+this.getData('id'), dataInfo).then((res) => {

					_this.loading = false;
					_this.resetForm();

					//show confirm alert
					_this.$swal({
						title: "Sucesso",
						text: "CompanyEvaluations atualizado com sucesso!",
						icon: "success"
					});
				}).catch((err) => {

					_this.loading = false;

					//show error alert
					_this.$swal({
						title: "Erro",
						text: "Falha ao cadastrar CompanyEvaluations. Tente novamente!",
						icon: "error"
					});
				});
			},
			resetForm() {
			}
		}
	}
</script>
